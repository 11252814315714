import React, { useState, useEffect } from "react";
import { Table, Badge, Dropdown } from "react-bootstrap";
import {
  getUserList,
  blockUser,
  deleteUser,
} from "../../services/UserServices/UserService";
import Spinner from "../components/Common/Spinner";
import { notifyError, notifyTopRight } from "../components/Common/Toaster";
import Pagination from "../components/Common/Pagination";
import { useLocation, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import moment from "moment";
import dummmyImg from "../../images/profile/images.jpeg";
import { RangeDatePicker } from "@y0c/react-datepicker";

const UsersTable = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const imgBaseURl = process.env.REACT_APP_IMAGE_BASEURL;
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [listLength, setListLength] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [imageForView, setImageForView] = useState("");
  const [view, setView] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    location?.state?.page ? location?.state?.page : 0
  );
  const [search, setSearch] = useState(
    location?.state?.search ? location?.state?.search : ""
  );
  const [initialFetch, setInitialFetch] = useState(true);
  const [gender, setGender] = useState(
    location?.state?.gender ? location?.state?.gender : "all"
  );
  const [status, setStatus] = useState(
    location?.state?.status ? location?.state?.status : "all"
  );
  const [isFormFilled, setIsFormFilled] = useState(
    location?.state?.isFormFilled ? location?.state?.isFormFilled : "all"
  );
  const [signUpType, setSignUpType] = useState("all");
  const [exportData, setExportData] = useState(true);
  const [newUsers, setNewUsers] = useState([]);
  const limit = 10;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateRangePickerKey, setDateRangePickerKey] = useState(1);
  function onDateChange(...args) {
    console.log(args, "args");
    setStartDate(moment(args[0]).format("YYYY-MM-DD"));
    {
      args[1]
        ? setEndDate(moment(args[1]).format("YYYY-MM-DD"))
        : setEndDate(null);
    }
  }
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const headers = [
    { label: "CONTACT ID", key: "_id" },
    { label: "EMAIL", key: "email" },
    { label: "FIRST NAME", key: "name" },
    { label: "LAST NAME", key: "" },
    { label: "SMS", key: "phoneNumber" },
  ];
  const getTableData = async () => {
    setLoader(true);
    try {
      const response = await getUserList(
        currentPage,
        limit,
        search,
        gender,
        status,
        isFormFilled,
        signUpType,
        startDate,
        endDate
      );
      setUsers(response.data.data.users);
      const total = response.data.data.count;
      setPageCount(Math.ceil(total / limit));
      setListLength(total);
      setLoader(false);
      setInitialFetch(false);
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
    }
  };
  function onReset() {
    // setDateRangePickerKey((prev) => prev + 1);
    setStartDate(null);
    setEndDate(null);
  }
  const onDelete = async (id) => {
    setLoader(true);
    try {
      const response = await deleteUser(id);
      notifyTopRight(response.data?.message);
      getTableData();
      setLoader(false);
    } catch (error) {
      notifyError(error.response?.data?.message);
      console.log(error.response, "error");
      setLoader(false);
    }
  };

  const onAction = async (id) => {
    setLoader(true);
    try {
      const response = await blockUser(id);
      notifyTopRight(response.data?.message);
      getTableData();
      setLoader(false);
    } catch (error) {
      notifyError(error.response?.data?.message);
      console.log(error.response, "error");
      setLoader(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, [currentPage, gender, status, isFormFilled, signUpType, endDate]);

  let timeoutId;
  // Define a helper function for debouncing
  const debounce = (func, delay) => {
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  const debouncedHandleFetch = debounce(getTableData, 500);
  useEffect(() => {
    if (initialFetch === false) {
      setCurrentPage(0);
      debouncedHandleFetch();
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [search]);

  const getTableData2 = async () => {
    try {
      const response = await getUserList(
        currentPage,
        10000,
        search,
        gender,
        status,
        isFormFilled,
        signUpType,
        startDate,
        endDate
      );
      // console.log(response.data.data.users,"");

      setNewUsers(response.data.data?.users);
    } catch (error) {
      console.log(error, "error");
    }
  };

  // useEffect(() => {
  //   getTableData2();
  // }, [exportData, gender, status, isFormFilled, signUpType]);
  return loader ? (
    <Spinner />
  ) : (
    <>
      <div className="d-block justify-content-between align-items-center">
        <div className="d-flex justify-content-between align-items-center">
          <div className="col-4">
            <div
              className="input-group border bg-white input-group-sm"
              style={{ borderRadius: "8px" }}
            >
              <input
                style={{
                  paddingBottom: "25px",
                  paddingTop: "25px",
                  borderRadius: "10px",
                  fontSize: "14px",
                }}
                type="text"
                name="table_search"
                className="form-control float-right border-0"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <div className="input-group-append">
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={getTableData}
                >
                  <i className="fa fa-search" />
                </button>
              </div>
            </div>
          </div>
          <RangeDatePicker
            // key={dateRangePickerKey}
            className="form-control"
            startText="Start"
            endText="End"
            startPlaceholder="Start Date"
            endPlaceholder="End Date"
            onChange={onDateChange}
          />
          {endDate && (
            <button
              type="button"
              className="btn btn-primary py-2"
              onClick={() => onReset()}
            >
              Reset Date
            </button>
          )}

          <div className="">
            <CSVLink
              filename={"Users-List.csv"}
              data={newUsers}
              headers={headers}
            >
              <button className="btn btn-primary">
                Export <i class="flaticon-381-send"></i>
              </button>
            </CSVLink>
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center gap-3 mt-2">
          {" "}
          <div className="mb-2 mb-md-0 w-100">
            <label className="text-black font-w600 fs-14">
              Filter by Gender
            </label>

            <select
              className="form-control"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
            >
              <option value={"all"}>All</option>
              <option value={"male"}>Male</option>
              <option value={"female"}>Female</option>
            </select>
          </div>
          <div className="mb-2 mb-md-0 w-100">
            <label className="text-black font-w600 fs-14">
              Sign Up Complete
            </label>

            <select
              className="form-control"
              value={isFormFilled}
              onChange={(e) => setIsFormFilled(e.target.value)}
            >
              <option value={"all"}>All</option>
              <option value={true}>Completed</option>
              <option value={false}>Not Completed</option>
            </select>
          </div>
          <div className="mb-2 mb-md-0 w-100">
            <label className="text-black font-w600 fs-14">
              Filter by Status
            </label>

            <select
              className="form-control"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value={"all"}>All</option>
              <option value={"enable"}>Enabled</option>
              <option value={"disable"}>Disabled</option>
              <option value={"deactivate"}>Deactivated</option>
              <option value={"delete"}>Deleted</option>
              <option value={"adminDeleted"}>Deleted By Admin</option>
            </select>
          </div>
          <div className="mb-2 mb-md-0 w-100">
            <label className="text-black font-w600 fs-14">
              Filter by Sign Up
            </label>
            <select
              className="form-control"
              value={signUpType}
              onChange={(e) => setSignUpType(e.target.value)}
            >
              <option value={"all"}>All</option>
              <option value={"email"}>Email</option>
              <option value={"phoneNumber"}>Phone Number</option>
              <option value={"social"}>Social Login</option>
            </select>
          </div>
        </div>
      </div>
      <Table responsive>
        <thead style={{ color: "black" }}>
          <tr>
            <th>
              <strong>Image</strong>
            </th>
            <th>
              <strong>NAME</strong>
            </th>
            <th>
              <strong>EMAIL</strong>
            </th>
            <th>
              <strong>Gender</strong>
            </th>
            <th>
              <strong>PHONE NUMBER</strong>
            </th>
            <th>
              <strong>Referral Code</strong>
            </th>
            <th>
              <strong>Redeemed by</strong>
            </th>
            <th>
              <strong>STATUS</strong>
            </th>
            <th>
              <strong>created at</strong>
            </th>
            <th>
              <strong>ACTION</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          {users?.map((item, i) => (
            <tr
              key={i}
              onClick={() =>
                navigate("/user-details", {
                  state: {
                    data: item?._id,
                    selectedTab: "users",
                    search: search,
                    page: currentPage,
                  },
                })
              }
              className="pointer"
            >
              <td>
                <img
                  src={
                    item?.images[0]?.url
                      ? imgBaseURl + item?.images[0]?.url
                      : dummmyImg
                  }
                  alt="img"
                  width={100}
                  height={80}
                  className="rounded"
                  onClick={() => {
                    setImageForView(imgBaseURl + item?.images[0]?.url);
                    setView(true);
                  }}
                />
              </td>
              <td>{item?.name}</td>
              <td>{item?.email}</td>
              <td>
                {item?.gender &&
                  item?.gender?.charAt(0).toUpperCase() +
                    item?.gender?.slice(1)}
              </td>
              <td>
                {item.countryCode && "+" + item.countryCode} {item?.phoneNumber}
              </td>
              <td>{item?.myReferralCode}</td>

              <td
                className={` ${item.referralCount > 0 ? "pointer" : ""}`}
                onClick={(event) => {
                  event.stopPropagation();

                  if (item.referralCount > 0) {
                    navigate("/redeemed-by", {
                      state: { id: item?.referralId },
                    });
                  }
                }}
              >
                {item.referralCount}
              </td>
              <td>
                {item?.isDeletedByAdmin ? (
                  <Badge bg="" className="badge-secondary">
                    Deleted by Admin
                  </Badge>
                ) : item?.isDeleted ? (
                  <Badge bg="" className="badge-secondary">
                    Deleted
                  </Badge>
                ) : item?.isDeactivated ? (
                  <Badge bg="" className="badge-warning">
                    Deactivated
                  </Badge>
                ) : item?.isBlocked ? (
                  <Badge bg="" className="badge-danger">
                    Disabled
                  </Badge>
                ) : (
                  !item?.isBlocked && (
                    <Badge bg="" className="badge-success">
                      Enabled
                    </Badge>
                  )
                )}
              </td>
              <td>{moment(item?.createdAt).format("MMM Do YYYY, h:mm A")}</td>
              <td
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                {!item?.isDeleted && (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="info light"
                      className="light sharp btn btn-info i-false"
                    >
                      {svg1}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {item.isBlocked ? (
                        <Dropdown.Item onClick={() => onAction(item._id)}>
                          Enable
                        </Dropdown.Item>
                      ) : (
                        <Dropdown.Item onClick={() => onAction(item._id)}>
                          Disable
                        </Dropdown.Item>
                      )}
                      <Dropdown.Item onClick={() => onDelete(item._id)}>
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {users?.length === 0 && !loader && (
        <div className="justify-content-center d-flex my-5">
          Sorry, Data Not Found!
        </div>
      )}
      {users?.length !== 0 && (
        <div className="card-footer clearfix">
          <div className="d-block d-sm-flex justify-content-between align-items-center">
            <div className="dataTables_info">
              Showing {currentPage * limit + 1} to{" "}
              {listLength > (currentPage + 1) * limit
                ? (currentPage + 1) * limit
                : listLength}{" "}
              of {listLength} entries
            </div>
            <Pagination
              pageCount={pageCount}
              pageValue={currentPage}
              setPage={setCurrentPage}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default UsersTable;
